/* #uploadwindow .k-window-titlebar {
  padding: 15px 10px 15px 10px;
  border-style: none;
} */
#uploadwindow .k-window-title {
    font-family: "ShellHeavy";
    font-size: 15.75px;
    padding-left: 10px;
    font-weight: normal;
  }
  #uploadwindow .k-window-titlebar{
    height: 25px
  }
  #uploadwindow .k-link {
    font-weight: normal;
  }
  
  #uploadwindow .k-window-content {
    padding-top: 2;
  }
  
  #uploadwindow .MuiDropzoneArea-root{
    width: 100%;
    border: dashed;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    min-height: 250px;
    border-color: #a6a6a6;
    border-radius: 4px;
    background-color: #202020d4;
    border-width: thin;
  }

  #uploadwindow .MuiDropzoneArea-text{
    margin-top: 35px;
    margin-bottom: 24px;
  }

  #uploadwindow .MuiDropzoneArea-icon {
    color: #a6a6a6;
    width: 51px;
    height: 51px;
  }

  #uploadwindow .MuiDropzonePreviewList-image{
    color: #7f7f7f7a;
    width: initial;
    height: 100px;
    opacity: 1;
    z-index: 5;
    max-width: 100%;
    box-shadow: rgb(0 0 0 / 12%) 0 1px 6px, rgb(0 0 0 / 12%) 0 1px 4px;
    box-sizing: border-box;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    border-radius: 4px;
  }
  /* Style the tab */
 /* .tab {
    overflow: hidden;
    border-bottom: 1px solid #595959;
  }
  
  /* Style the buttons inside the tab */
  /*.tab li {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    transition: 0.3s;
    font-size: 14px;
    color: #a6a6a6;
    border-bottom: 3px solid #202020;
  }
  
  /* Change background color of buttons on hover */
  /*.tab li:hover {
    background-color: #282828;
    color: #f7f7f7;
  }
  
  /* Create an active/current tablink class */
 /* .tab li.active {
    border-bottom: 3px solid #003c88;
    color: #f7f7f7;
    z-index: 999;
  }
  
  /* Style the tab content */
  /*.tabcontent {
    display: none;
    /* padding: 6px 12px; */
   /* border-top: none;
    height: 650px;
  }
  .defalut-height {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .pannel {
    border: 1px solid #595959;
    background-color: #282828;
    padding: 20px 25px 25px 25px;
  }*/
  
  #uploadwindow .background-filter-fill {
    background-color: #282828;
  }
  .red-text {
    color: red;
    font-size: 9px !important;
  }
  #uploadwindow .k-dropdown,
  #uploadwindow .k-textbox,
  #uploadwindow .k-datepicker,
  #uploadwindow .k-dateinput,
  #uploadwindow .k-multiselect,
  #uploadwindow .k-widget,
  #uploadwindow .k-numerictextbox {
    width: 100%;
    border-color: #595959;
    color: white;
    background-color: #303030;
    height: 35px;
  }
  #uploadwindow .k-input,
  #uploadwindow .k-select,
  #uploadwindow .k-dropzone {
    background-color: #303030;
    padding-top: 10px;
    padding-right: 5px;
    /*height: 35px;*/
  }
  
  #uploadwindow .k-textbox.k-state-invalid {
    color: #dc3545;
    border-color: #dc3545;
  }
  #dailyeventListing .custominput[type="radio"] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid darkgray;
    border-radius: 80%;
    outline: none;
    box-shadow: 0 0px 0px 0px gray inset;
  }
  #dailyeventListing .custominput[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    margin-left: 24%;
    border-radius: 50%;
  }
  #dailyeventListing .custominput[type="radio"]:checked:before {
    background: #007bff
    ;
  }
 /* .titlebar {
    border-bottom: 1px solid #595959;
    margin-bottom: 28px !important;
    font-size: 12px;
    height:20px;
  }
 /* .devider {
    font-size: 20px;
    margin-right: 20px;
    color: #a6a6a6;
    border-right: 1px solid #595959;
  }*/
 /* .k-checkbox-label,
  .k-radio-label {
    color: #a6a6a6;
  }
  .textareaInput {
    width: 93.4%;
    height: 100px;
    background-color: rgb(32, 32, 32);
    border-color: rgba(255, 255, 255, 0.125);
    color: rgb(217, 217, 217);
  }
  .pt-30 {
    padding-top: 30px;
  }
  .linkicon {
    position: absolute;
    z-index: 999;
    right: 78px;
    font-size: 16px;
    padding: 8px;
  }*/
  #uploadwindow .k-widget {
    /* width: 79.8%; */
  }
  #uploadwindow .k-file-extension-wrapper,
  #uploadwindow .k-file-name-size-wrapper {
    display: none;
  }
  #uploadwindow .k-file-size {
    position: absolute;
    color: #a6a6a6;
    top: -57px;
    right: 0px;
    font-size: 12px;
  }
  #uploadwindow .k-upload-status {
    position: absolute;
    top: -31px;
    height: 0;
    padding: 0;
  }
  #uploadwindow .k-upload-status-total {
    top: 19px;
    left: 107px;
    color: green;
  }
  #uploadwindow .k-file-error,
  #uploadwindow .k-reset,
  #uploadwindow .k-file-progress {
    padding: 0 !important;
  }
  #uploadwindow .k-reset {
    background-color: #282828;
    border-color: #282828;
  }
 /* .h-container {
    background-color: #303030;
  }
  .h-container th {
    color: #a6a6a6;
    font-weight: normal;
  }
  .h-container td {
    color: #f7f7f7;
    padding-top: 5px;
  }
  .text-gray {
    color: #a6a6a6;
  }
  .bd-tb {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
  #unit-cross-reference .k-widget {
    width: 100%;
  }
  */
  #dailyeventListing table {
    width: 100%;
  }
  #dailyeventListing th {
    background-color: #282828 !important;
    box-shadow: inset 0 1px 0 0 #404040, inset 0 -1px 0 0 #404040;
    color: #d9d9d9;
    line-height: 19px;
    /*font-family: "ShellMedium";*/
    border-top: 1px solid #404040;
    text-align: left;
    padding-left: 20px;
    font-weight: normal;
  }
  
  #dailyeventListing .k-grid-header-wrap {
    padding: 0;
  }
  
  #dailyeventListing .k-link {
    font-size: 15px;
    font-weight: bold;
  }
  
  #dailyeventListing tr:nth-child(odd) {
    background-color: #303030;
  }
  
  #dailyeventListing tr:nth-child(even) {
    background-color: #383838;
  }
  #dailyeventListing td {
    padding: 8px 8px 8px 20px;
    /* width: 10%; */
    border-width: 1px 0 1px 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  #dailyeventListing .k-alt {
    background-color: #383838;
  }
  #dailyeventListing tr:hover {
    background-color: rgb(0, 60, 136, 0.2);
  }
  #dailyeventListing .k-grid {
    background-color: #202020 !important;
    border: none !important;
  }
  #dailyeventListing .k-header:last-child {
    border-right: none !important;
  }
  #dailyeventListing .k-grid-footer-wrap,
  .k-grid-header-wrap {
    border-width: 0;
    padding-right: 10px;
    /* background-color: rgb(24, 24, 24) !important; */
  }
  #dailyeventListing .k-state-selected {
    background-color: #003c88 !important;
  }
  .k-multiselect {
    position: relative;
}

.k-multiselect:after {
    content: "\25BC";
    position: absolute;
    top: 25%;
    right: 10px;
    font-size: 12px;
}

.k-multiselect.opened:after {
    content: "\25C0";
}
