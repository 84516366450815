.maintenance-select label {
  font-size: 12px;
  position: absolute;
  top: -10px;
  padding: 0 8px;
  z-index: 99;
  left: 0.5rem;
}

.global-asset-db i.fa.fa-calendar,
.global-asset-db .k-input,
.global-asset-db .k-button {
  font-size: 14px;
}

.button-filter {
  position: relative;
}

.dropdown-dialog {
  position: absolute;
  z-index: 100;
  min-width: 20rem;
  top: 2.7rem;
  min-height: 250px;
  font-size: 14px;
}

.current-year .k-dropdown-wrap {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-filter .k-datepicker {
  width: 16rem;
}

.button-filter .k-select {
  border-left: 0;
  padding: 0.5rem 1rem;
  padding-left: 0;
}

.checkbox-filter .k-checkbox-label {
  margin-top: 0.45rem;
}

.delta.dropdown-dialog {
  right: 0;
  font-size: 14px;
}

.delta-buttons .k-item {
  border: 0;
  padding: 0;
  border-radius: 0;
  background: none;
  font-size: 14px;
}

.delta-buttons .k-link {
  padding: 0.5rem 0.72rem;
}

.version-selectors input {
  text-align: center;
}

.version-selectors label {
  margin-bottom: 0;
}

.version-selectors select {
  padding: 4px;
  background: none;
  width: 100%;
}

label.select:after {
  content: "\f0d7";
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  padding: 0.5rem 1rem;
  pointer-events: none;
  box-sizing: border-box;
}

.delta-submit-button,
.delta-buttons .k-animation-container {
  width: 100%;
}

.sub-dialog-view {
  font-size: 14px;
}

.dropdown-dialog:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 8px 10px;
  border-color: transparent transparent #595959 transparent;
  top: -9px;
  position: absolute;
  z-index: 99;
}

.delta.dropdown-dialog:before {
  right: 10px;
}

.absolute.dropdown-dialog:before {
  left: 10px;
}

.global-asset-db .table {
  border-collapse: separate;
  margin-bottom: 0.15rem;
}

.global-asset-db .table.days-in-month td {
  padding: 0.7rem 0 0.25rem;
}

.global-asset-db .table td.data {
  background: #303030;
}

.global-asset-db .weekly-view tr.data-tr,
.global-asset-db .mqy-view tr.data-tr {
  background: #303030;
}

.global-asset-db .weekly-view td,
.global-asset-db .mqy-view td {
  padding: 5px 0;
}

.table .weekly-view-year,
td.data-title {
  vertical-align: middle;
}

.global-asset-db .weekly-view td.day-month {
  color: #7f7f7f;
}

.data-tr .border {
  border: 1px solid #595959 !important;
}

.text-h span {
  background: none;
  text-transform: uppercase;
  font-size: 12px;
  transform-origin: 0 0;
  margin: 3px;
}

.weekly-view .text-h.less-providers {
  margin-top: 30px;
}

.mqy-view .text-h.less-providers {
  margin-top: 14px;
}

.ftd-w {
  width: 80px;
}

.fth-w {
  width: 106px;
}

.no-bgc {
  background: none !important;
}

.m-text {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: #d9d9d9;
  background-color: transparent;
  background-clip: padding-box;
  border: none !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .mqy-view{
    max-width:1200px;
  } */

.global-asset-db td.month {
  width: 24px;
  height: 84px;
}

.global-asset-db td.month.less-providers {
  width: 24px;
  height: auto;
}

.global-asset-db table {
  position: relative;
}
.global-asset-db tr {
  position: relative;
}

.text-h {
  height: 24px;
  text-align: center;
  position: absolute;
  left: 0;
  transform-origin: top left;
}
.weekly-view .text-h {
  -webkit-transform: rotate(-90deg) translate(-165%);
  transform: rotate(-90deg) translate(-165%);
}

.daily-view .text-h {
  -webkit-transform: rotate(-90deg) translate(-126%);
  transform: rotate(-90deg) translate(-126%);
}
.mqy-view .text-h {
  -webkit-transform: rotate(-90deg) translate(-146%);
  transform: rotate(-90deg) translate(-146%);
}

.weekly-view table:first-child {
  margin-top: 1rem;
}

.text-h.less-providers {
  transform: none !important;
  position: relative !important;
  top: 25px !important;
  left: 0 !important;
}

.length-2 .text-h.less-providers {
  height: 50px;
  padding: 15px 0;
}

.mqy-view .length-2 .text-h.less-providers {
  height: 50px;
  padding: 15px 0;
}

.global-asset-db .tb-head {
  padding-left: 112px;
}
.global-asset-db .tb-head.less-providers {
  padding-left: 170px;
}

.maintenance-datasheet thead tr th:first-child,
.maintenance-datasheet tbody.actual-data tr td:first-child {
  padding: 0 12px 0 12px;
}

.global-asset-db table {
  background: none;
}

.global-asset-db .table td.data-td {
  background: #303030;
}

.delta.dropdown-dialog .k-link {
  font-size: 12px;
}

.global-asset-db .options {
  position: relative;
}

.maintenance-select {
  position: absolute;
  background: #202020;
  z-index: 99;
  top: 66px;
  width: 100%;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  height: 435px;
}

.selected-item {
  border: 1px solid #7f7f7f;
  padding: 0.5rem 1rem;
  border-radius: 25px;
}

.reset-clear {
  color: #f7f7f7;
  background: #202020;
  border: 1px solid #7f7f7f;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}

.reset-clear:hover {
  background-color: #303030;
}

.tab-focus:focus {
  border-color: #ffffff;
}

.filter .fa {
  color: #7f7f7f;
  font-size: 20px;
}

.options-li li {
  display: inline-block;
  margin-right: 0.75rem;
}

.selected-long-list {
  max-height: 51px;
  overflow: hidden;
}

.reset-clear {
  z-index: 999;
}

.selections button {
  margin: 5px 0;
}

.selected-item span {
  cursor: pointer;
  margin-left: 1rem;
}

.total-selected-items {
  padding: 0.5rem 1rem;
}

.total-selected-items span {
  color: #fff;
}

.all-selected-items {
  position: absolute;
  background: #202020;
  z-index: 99;
  top: 66px;
  width: 100%;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  height: 400px;
}

.selected-item-list li {
  display: inline-block;
}

.selected-item-list li button {
  padding-right: 0;
}

.all-selected-items .row,
.all-selected-items .row:active {
  border-top: 1px solid #595959 !important;
  border-left: 1px solid #595959 !important;
  border-right: 1px solid #595959 !important;
}

.all-selected-items .row:last-child {
  border-bottom: 1px solid #595959 !important;
  margin-bottom: 1rem !important;
}

.table td,
.table th {
  padding: 0;
}

.maintenance-select::before {
  /* content: "\f077";
    font-family: FontAwesome;
    width: 30px;
    position: absolute;  */
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #c2e1f5;
  border-width: 36px;
  margin-left: -36px;
  width: 30px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.global-asset-db .table td.data.bg-green {
  background: #008443;
  border: 1px solid #66b58e !important;
  font-weight: 900;
}

.global-asset-db .table td.data.bg-green input {
  font-weight: 900;
}

.global-asset-db .table td.data.bg-red {
  background: #dd1d21;
  border: 1px solid #f1a5a6 !important;
  font-weight: 900;
}

.global-asset-db .table td.data.bg-red input {
  font-weight: 900;
}

.global-asset-db a.active {
  background: #003c88;
}

.page-title h3 {
  font-family: "ShellMedium";
  font-size: 1.6rem;
}

.add-edit-btn {
  background-color: #ccd8e7;
  padding: 0.5rem 1rem;
  color: #3363a0;
  font-family: "ShellMedium";
  font-size: 14px;
  font-weight: 900;
  border-radius: 8px;
  line-height: 20px;
}

.add-edit-btn i {
  color: #3363a0;
  font-size: 14px;
  margin-right: 1rem;
  font-weight: bold;
}

.download-btn i {
  color: #ccd8e7;
  font-size: 1.6rem;
}

.maintenance-filter a,
.maintenance-filter span.k-widget,
.maintenance-filter button {
  font-family: "ShellMedium";
}

.weekly-view .data-td {
  min-width: 75px;
}

.simplebar-horizontal {
  visibility: hidden;
}

.pop-over {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.title,
.comment-box-title {
  color: #a6a6a6;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 17px;
  z-index: 99;
  left: 5px;
  background: #202020;
  top: -10px;
  padding: 0 5px;
}

.modal-window .k-dropdown-wrap.k-state-default {
  border: 0;
}

.unit-capacity-in-db-bg {
  border-radius: 4px 0 0 4px;
  background-color: #ffeac2;
}

.unit-capacity-in-db p {
  color: #404040;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.unit-capacity-in-db-bg {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.k-window-content {
  overflow: hidden;
}

.global-asset-db .k-window-wrapper,
.global-asset-db .k-dropdown-wrap,
.global-asset-db .modal-window input,
.global-asset-db .input-group-text {
  background: #202020 !important;
}

.input-group-text i {
  color: #fff;
}

.global-asset-db .k-header {
  border: 0;
  background-color: #003c88;
}
.k-window-titlebar {
  padding: 2rem 2rem 2rem 2rem;
}

.k-window-title,
.entry-button {
  font-family: "ShellBold";
}

.k-window {
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  background: #202020;
}

.k-header button.k-button-icon.k-button.k-bare:nth-child(0) {
  display: none !important;
}
.k-header button.k-button-icon.k-button.k-bare:nth-child(1) {
  display: none !important;
}

.select-options-bg option {
  background-color: #404040;
  color: #fff;
}

.td-scroller {
  padding-bottom: 1.6rem;
}

html {
  overflow: -moz-scrollbars-none;
}

:-moz-any(#content, #appcontent) browser {
  margin-right: -14px !important;
  overflow-y: scroll;
  margin-bottom: -14px !important;
  overflow-x: scroll;
}

.search-filter {
  border: 1px solid #7f7f7f;
  background: #202020;
  color: #f7f7f7;
  border-radius: 8px;
  padding: 0.25rem 0 0.25rem 0.5rem;
  margin: 0 0 1rem;
}

.has-search .form-control-feedback {
  position: absolute;
  top: 4px;
  right: 10px;
}
.delta-popup {
  margin: 15px 0 0 280px;
  padding: 20px;
  position: relative;
  border: 1px solid #595959;
  border-radius: 4px;
  background-color: #202020 !important;
}

.delta-popup .k-datepicker {
  width: 100%;
}

.delta-popup:after,
.delta-popup:before {
  bottom: 100%;
  left: 8%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.delta-popup:after {
  /* border-color: rgba(136, 183, 213, 0); */
  border-bottom-color: #202020;
  border-width: 10px;
  margin-left: -10px;
}

.delta-popup:before {
  /* border-color: rgba(194, 225, 245, 0); */
  border-bottom-color: #595959;
  border-width: 12px;
  margin-left: -12px;
}

.delta-popup .k-input {
  font-size: 14px;
  height: 35px;
  font-weight: lighter;
}

#popup-content {
  margin: 0 auto;
}

.delta-popup .delta-dates {
  width: 70%;
  border-right: 0px;
}

.delta-popup .k-state-focused {
  border-color: #595959;
}

.delta-popup .k-select {
  border-left: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer * {
  cursor: pointer;
}

.filter-popup {
  margin-top: 10px;
  margin-left: -15px;
}

.filter-popup:nth-child(even) {
  display: none;
}

.topnav-span-text {
  font-weight: 600;
  font-size: 14px;
}

.topnav-span-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  bottom: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #595959;
}

.slider-link {
  background-color: #003c88;
}

.slider-toggle {
  background-color: #003c88;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 3px;
  background-color: #d0d0d0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #003c88;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
  background-color: #d0d0d0;
}

/* input:checked + .slider-link:before {
  background-color: #fff;
}

input:checked + .slider-toggle:before {
  background-color: #d0d0d0;
} */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.disabled-toggle {
  color: #7f7f7f;
}

.promote-popup {
  margin-top: 10px;
  padding: 5px;
  position: relative;
  border: 1px solid #595959;
  border-radius: 4px;
  background-color: #202020 !important;
}

.promote-popup:after,
.promote-popup:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.promote-popup:after {
  border-bottom-color: #202020;
  border-width: 10px;
  margin-left: -10px;
}

.promote-popup:before {
  border-bottom-color: #595959;
  border-width: 12px;
  margin-left: -12px;
}

.config-badge {
  cursor: pointer;
  background-color: #202020;
}

.config-badge:hover {
  background-color: #303030;
}

.toggle-dropdown button {
  border-radius: 4px 0px 0px 4px !important;
  border-right: 0px !important;
}
