.events-window .k-window-content {
  padding: 0;
}

.events-window .k-window .k-window-titlebar {
  padding: 1.5rem 1.5rem 1px;
  position: relative;
}

.events-window .k-window-wrapper > .k-window-titlebar button:nth-child(-n + 3) {
  display: none;
}

.events-window .k-bare {
  position: relative;
  top: -26px;
  right: -9px;
  color: #7f7f7f;
}

.events-window .k-dropdown-wrap {
  border: 0;
  background: none;
}

.events-window .k-i-close {
  font-size: 20px;
}

#multiSelect .k-i-close {
  font-size: 12px;
}

.events-window .input {
  width: 50px;
}

.events-window .input input {
  outline: 0;
}

#event-listing table {
  width: 100%;
}
#event-listing th {
  background-color: #282828 !important;
  box-shadow: inset 0 1px 0 0 #404040, inset 0 -1px 0 0 #404040;
  color: #a6a6a6;
  font-size: 14px;
  line-height: 19px;
  font-family: "ShellLight";
  border-top: 1px solid #404040;
  width: auto;
}

#event-listing tr {
  background-color: #303030;
}

#event-listing tr:nth-child(even) {
  background-color: #383838;
}
#event-listing tr:hover {
  background-color: rgb(0, 60, 136, 0.2);
}

#event-listing tr.selected-row {
  background-color: #003c88;
}

.ellipse-table {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

li.capacity-event-tab-btn.active,
li.maintenance-event-tab-btn.active {
  border-bottom: 1px solid #003c88;
  color: #f7f7f7;
  z-index: 999;
}

.maintenance-currentyear-dropdown button {
  font-size: 12px;
}

.incremental-capacity input:focus {
  border: none;
  outline: 0px;
}

#multiSelect .k-button {
  transition: color 0.2s ease, background-color 0.2s ease,
    border-color 0.2s ease, box-shadow 0.2s ease;
  background-color: #303030;
}

button.k-button.k-state-active.history {
  background-color: #003c88 !important;
}

button.action,
li.action {
  background-color: #1d1d1d;
}

button.action:hover,
li.action:hover {
  background-color: #303030;
}

#multiSelect .k-button {
  background-color: #1d1d1d;
}

#multiSelect .k-button:hover {
  background-color: #303030;
}

.url-input {
  width: 95%;
  margin-left: 5%;
}

.maintenance-event-grid div.k-state-focused button {
  background-color: #1d1d1d;
}

.maintenance-event-grid div.k-state-focused button.k-primary {
  background-color: #003c88;
}

/* .datetime .datepicker span.k-select {
  padding: 0;
  position: absolute;
  top: 8px;
  width: 100%;
  align-items: end;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  right: 0;
} */
