.background-filter-fill {
  background: #282828;
}

.popup-content {
  padding: 10px;
  border: 1px solid #7f7f7f;
}

.fa {
  color: #7f7f7f;
}

.topbar {
  font-size: 12px;
  padding: 0.64rem 6px;
}

.select-buttons {
  margin-left: 4rem;
}

.dim-text {
  color: #a6a6a6;
  cursor: pointer;
}

.capacity i.fa.fa-calendar {
  position: relative;
  top: -2px;
}

.capacity-row-title {
  font-family: "ShellHeavy";
  color: #a6a6a6;
  font-size: 15.75px;
  /* font-weight: 900; */
  line-height: 22px;
}

.capacity-type-selection button.k-button {
  font-size: 12px;
}

.capacity .k-datepicker .k-picker-wrap,
.capacity .k-datepicker {
  border: 0;
  background: none;
  line-height: 1;
  width: auto;
}

.capacity .k-select {
  border: 0;
  background: no-repeat;
  padding: 0;
  display: block;
}

.capacity .dropdown-toggle::after {
  display: none;
}

.normal-text {
  color: #f7f7f7;
  cursor: pointer;
}

.capacity .dropdown .dropdown-menu {
  border: 1px solid #595959;
  border-radius: 4px;
  top: 10px !important;
  left: -15px !important;
}

.capacity .dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.capacity .dropdown.show .dropdown-menu {
  /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
  max-height: 300px;
  opacity: 1;
}

.capacity-data-row::before {
  content: "";
  position: absolute;
  bottom: 25%;
  border-bottom: 1px solid #595959;
  width: 100%;
  z-index: 0;
}

.z-index {
  z-index: 99;
}

.events-title {
  font-family: "ShellHeavy";
  font-size: 15.75px;
  line-height: 22px;
}

.events .k-window-titlebar {
  padding-top: 1.5rem;
  padding-bottom: 1px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}

.events-process li {
  font-family: "ShellHeavy";
  font-size: 14px;
}

.events .k-window-content {
  padding: 0;
}

.unit-capacity-in-db p.fs-16 {
  font-size: 16px;
}

.events .k-dropdown-wrap {
  border: 0;
  background: none;
}

li.capacity-events-button,
li.maintanence-events-button {
  padding-bottom: 15px;
  color: #a6a6a6;
  border-bottom: 1px solid #595959;
  padding-top: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

li.capacity-events-button.active,
li.maintanence-events-button.active {
  border-bottom: 1px solid #003c88;
  /* border-bottom: 2px solid rgb(197, 197, 0) !important; */
  color: #f7f7f7;
  z-index: 999;
}

li.delta-events-button {
  padding-bottom: 15px;
  color: #a6a6a6;
  border-bottom: 1px solid #595959;
  /* padding-top: 0.8rem; */
  padding-right: 1rem;
  padding-left: 1rem;
}

li.delta-events-button.active {
  border-bottom: 1px solid #003c88;
  /* border-bottom: 2px solid rgb(197, 197, 0) !important; */
  color: #f7f7f7;
  z-index: 999;
}

.events .k-bare {
  position: relative;
  top: -26px;
  right: -9px;
  color: #7f7f7f;
}
.events .k-i-close {
  font-size: 22px;
}
.events .input {
  width: 50px;
}

.events .input input {
  outline: 0;
}

.information span {
  background-color: #332f27;
}

.datetime .k-picker-wrap {
  border: 0;
  background: none;
}

.datetime .k-dateinput-wrap {
  background: none;
}

.datetime .k-select {
  background: none;
  border: 0;
}

.vbottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.input-group .k-upload-button {
  background-color: transparent;
  position: absolute;
  border: 0;
  top: 0;
  bottom: 1px;
  right: 0px;
}

.input-group .k-upload-button span {
  visibility: hidden;
}

.input-group .k-upload-button span::after {
  font-family: FontAwesome;
  content: "\f093";
  font-size: 18px;
  color: gray;
  visibility: visible;
}

.input-group .k-dropzone {
  height: 30px;
  background: #202020;
  border: 1px;
}

.event-action-button button {
  font-family: "shellHeavy";
}

.capacity-select {
  position: absolute;
  background: #202020;
  z-index: 999;
  top: 65px;
  width: 100%;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  height: 400px;
}

.capacity-select .list-group-item {
  padding: 0.256rem 10px;
  color: #f7f7f7;
  background: none;
  border: 0;
}

.no-bg-button-icon {
  outline: none;
  box-shadow: none;
}

.vbottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.input-group .k-upload-button {
  background-color: transparent;
  position: absolute;
  border: 0;
  top: 0;
  bottom: 2px;
  right: 0px;
}

.input-group .k-upload-button span {
  visibility: hidden;
}

.input-group .k-upload-button span::after {
  font-family: FontAwesome;
  content: "\f093";
  font-size: 18px;
  color: gray;
  visibility: visible;
}

.input-group .k-dropzone {
  height: 30px;
  background: #202020;
  border: 1px;
}

.event-action-button button {
  font-family: "shellHeavy";
}
.event-action-button .btn-default {
  border-color: azure;
}

.datepicker span.k-select {
  padding: 0;
  right: 20px;
  position: absolute;
  top: 8px;
}

.attachment .k-upload {
  background: transparent;
  border-radius: 4px;
}

.attachment.input-group .k-dropzone {
  background: transparent;
  border: 0;
}

td.providers {
  text-align: right;
}

.mt-custom {
  margin-top: -5px;
}

.capacity-type-selection,
.capacity-type {
  display: none;
}

.capacity-grid {
  height: auto;
  /* max-height: 420px; */
  padding-bottom: 20px;
  overflow-x: auto;
}

.simplebar-wrapper {
  height: auto;
  max-height: 310px;
}

.pl-3.pr-0.multi-select-scroll.region {
  width: 10.8%;
}

.capacity-select:after,
.capacity-select:before {
  bottom: 100%;
  left: 2%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.capacity-select:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #595959;
  border-width: 18px;
  margin-left: -2rem;
}

.capacity-select:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #202020;
  border-width: 15px;
  margin-left: -1.77rem;
}

.k-tabstrip-top > .k-tabstrip-items li.k-item {
  font-family: ShellBold;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  padding: 0 0.37rem;
}

.k-tabstrip-top > .k-tabstrip-items li.k-item:hover {
  border: 0;
}

.k-tabstrip > .k-content {
  border: 0;
}

.capacity-delta-dialog {
  position: absolute;
  top: 100%;
  z-index: 99;
  right: 27.7%;
}

.capacity-delta-dialog p,
.capacity-delta-dialog li {
  text-align: left;
}

.capacity-delta-dialog li {
  padding: 9px;
  border-bottom: 1px solid #595959;
}

.show-delta {
  background: #003c88;
}

.spinner-input {
  width: 100px;
}
.spinner-input input {
  text-align: right;
}
.spinner-input .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.spinner-input .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin-left: -1px;
  position: relative;
  border-radius: 0;
}
.spinner-input .input-group-btn-vertical > .btn:first-child {
  border-top-right-radius: 4px;
}
.spinner-input .input-group-btn-vertical > .btn:last-child {
  margin-top: -2px;
  border-bottom-right-radius: 4px;
}
.spinner-input .input-group-btn-vertical i {
  position: absolute;
  top: 0;
  left: 4px;
}

.capacity-delta-dialog:after,
.capacity-delta-dialog:before {
  bottom: 100%;
  right: 3%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.capacity-delta-dialog:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #202020;
  border-width: 10px;
  margin-right: 2px;
}

.capacity-delta-dialog:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #595959;
  border-width: 12px;
  margin-left: -30px;
}

.download-options:after,
.download-options:before {
  bottom: 100%;
  right: 1%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.download-options:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #202020;
  border-width: 10px;
  margin-right: 2px;
}

.download-options:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #595959;
  border-width: 12px;
  margin-left: -30px;
}

.download-options {
  right: 15px;
  top: 45px;
}

.download-title {
  font-family: ShellHeavy;
  font-size: 15.75px;
  line-height: 22px;
}

.download-options,
.download-options li {
  text-align: left;
}

.download-close {
  top: 20px;
  right: 20px;
}

.download-close .fa {
  font-size: 24px;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .capacity-select:after,
  .capacity-select:before {
    left: 3%;
  }
}

.versionwindow {
  position: absolute;
  background: #202020;
  z-index: 1000;
  width: 650px;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  height: 300px;
  right: 20px;
}

.version-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.border-tb {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
span.version-close {
  cursor: pointer;
}

.versionGrid .k-grid-header .k-header > .k-link {
  padding: 0.2rem;
  font-size: 12px;
  color: #a6a6a6;
  text-overflow: unset;
  text-align: center;
  width: 100% !important;
}

.versionGrid .k-grid-header {
  border-bottom-width: 0px;
  background-color: transparent !important;
}

.versionGrid > .k-grid th {
  border-style: none;
}

.k-grid-header .k-header > .k-link {
  font-size: 13px;
  text-overflow: unset;
}

.versionGrid .k-grid-container .k-master-row td {
  font-size: 12px;
  text-align: center;
  border-color: transparent !important;
}

.versionGrid > .k-widget {
  height: 200px !important;
  /* width: 800px !important; */
  border-style: none;
}

.popup-content-version {
  padding: 20px;
  color: #787878;
  border: 1px solid gray;
  background-color: #202020;
}

body > .version-popup {
  left: 24.5% !important;
  right: 23% !important;
}
.version-data-grid {
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.version-icon {
  font-family: "Open Sans";
  color: #a6a6a6;
  font-size: 0.9em;
  text-decoration: underline;
}

.version-icon-empty {
  font-family: "Open Sans";
  color: #a6a6a6;
  font-size: 0.9em;
  text-decoration: none;
}
/* 
.versionGrid .k-grid-content {
  overflow-y: unset !important;
} */

/* .event-area .versionEditGrid .k-grid-content {
  overflow: scroll !important;
  overflow-x: hidden !important;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.event-area .versionEditGrid .k-grid-content::-webkit-scrollbar {
  height: 10px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* #capacityscreen th {
  background: #202020;
  margin: 0;
  padding: 0 4px 4px 0;
  position: relative;
  vertical-align: bottom;
  cursor: default;
  border: 1px solid #404040;
  border-radius: 4px;
} */

#capacityscreen .k-grid,
#capacityscreen .k-grid-header {
  background: transparent;
  border: 0;
}

#capacityscreen .k-grid .k-header a {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
}

#capacityscreen .k-grid th,
#capacityscreen .k-grid td {
  padding: 0;
}

/* #capacityscreen .k-grid td {
  border: 0;
  padding: 0;
} */
#capacityscreen .k-grid tbody tr.k-state-hover,
#capacityscreen .k-grid tbody tr:hover {
  background: none !important;
}
#capacityscreen .k-grid-header .k-header > .k-link {
  margin: 1px 0 1px 0;
  padding: 7px 0;
}

#capacityscreen .k-grid td .td-data {
  /* width: 100%; */
  padding: 6px;
  border: 1px solid #404040;
  border-radius: 4px;
  text-align: center;
  margin: 0 5px 5px 0;
}
/* #capacityscreen .k-header {
  margin: 1px 0 1px 0 !important;
} */

#capacityscreen .k-grid .k-alt {
  background: transparent;
}

#capacityscreen .k-grid td .td-data {
  background-color: #303030;
}

#capacityscreen .k-grid .k-alt td .td-data {
  background-color: #383838;
}

#capacitycontainer .k-grid table {
  width: 145px !important;
}

/* #capacityscreen .k-grid tr:first-child .k-header:first-child a {
  display: none;
} */

/* #capacityscreen .k-grid tr:first-child th:nth-child(2){

} */

#capacityscreen .k-grid-header-wrap {
  border: 0;
}

#capacityscreen .k-grid tbody tr.k-state-hover,
#capacityscreen .k-grid tbody tr:hover {
  background-color: rgba(217, 217, 217, 0.065);
}

#capacityscreen .k-column-resizer {
  right: 20px !important;
  top: 6px !important;
  /* display: none !important; */
}

#capacityscreen .k-grid th:hover .k-column-resizer {
  display: block !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid darkgray;
  border-radius: 80%;
  outline: none;
  box-shadow: 0 0px 0px 0px gray inset;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  margin-left: 24%;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: #668ab8;
}

/*To hide Minimise and Maximise button of Add/Edit Event Kendo Window*/
.events > .k-window-wrapper > .k-window-titlebar button:nth-child(-n + 3) {
  display: none;
}

.events > .k-window-wrapper > .k-window-titlebar button:nth-child(3) {
  position: relative;
  top: -25px;
}

.ellipsis {
  white-space: nowrap;
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: left;
}

.f-panel {
  width: 12%;
  float: left !important;
  position: relative;
}
.f-panel .undefined {
  background: none !important;
  border: none !important;
}
.f-panel .k-searchbar {
  width: 100% !important;
}
.f-panel .k-i-close {
  display: none !important;
}
.f-panel .k-i-search {
  position: absolute;
  z-index: 1;
  right: 10px;
  font-size: 20px;
  top: 47px;
  color: #7f7f7f;
}

/* #capacityscreen .k-grid td .td-data.tr-td-provider {
  width: 114px;
} */

.calender .ss-main .ss-single-selected,
.calender .ss-main .ss-content {
  border: 1px solid #595959;
}

.dropdown-multiselect .ss-main .ss-multi-selected {
  background: transparent;
  color: #f7f7f7;
  border: 0;
  height: 21px;
  padding: 1px;
}

.dropdown-multiselect .ss-main .ss-multi-selected .ss-values .ss-disabled {
  padding: 0 5px;
  margin: 0px;
}

.dropdown-multiselect .ss-main .ss-multi-selected .ss-add {
  margin: 5px 2px;
}

.dropdown-multiselect .ss-content,
.dropdown-multiselect .ss-content.ss-open {
  width: 200px !important;
  left: -72px;
  top: 33px;
}

.dropdown-multiselect .ss-main .ss-multi-selected .ss-values {
  display: none;
}

.dropdown-multiselect .ss-main .ss-content {
  border: 1px solid #595959;
  border-radius: 3px;
}

.items-selected,
.selection-pot {
  float: left;
}

.items-selected {
  padding: 0 5px;
}

.dropdown-multiselect .ss-main {
  position: unset;
}
.css-1rhbuit-multiValue {
  margin: 0 !important;
}

.dropdown-multiselect {
  position: relative;
  height: 20px;
}

/* .ss-option {
  display: inline-block;
} */

.dropdown-multiselect .ss-option::before {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.15);
  content: "";
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  display: inline-flex;
  border-radius: 0.25rem;
  margin-right: 8px;
}

.dropdown-multiselect .ss-main .ss-content {
  background-color: #202020;
}

.dropdown-multiselect .ss-main .ss-content .ss-list .ss-option:hover,
.dropdown-multiselect .ss-main .ss-content .ss-list .ss-option.ss-highlighted {
  background-color: #101010;
}

.dropdown-multiselect
  .ss-main
  .ss-content
  .ss-list
  .ss-option:not(.ss-disabled).ss-option-selected {
  color: #ffffff;
  background-color: transparent;
}

.dropdown-multiselect .ss-option.ss-option-selected::before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 10px;
  padding-left: 1px;
  border-color: #003c88;
  background-color: #003c88;
}
/* 
.ss-option.ss-option-selected::before {
}

.ss-option::after {
  content: "\e118";
  width: 16px;
  height: 16px;
  transform: scale(0);
} */
.bdc-solid {
  border-color: #202020 !important;
}

#capacityscreen .card-header {
  padding: 0;
  background: #202020;
  height: 60px;
}

#capacityscreen .accordion {
  width: 100%;
}
#capacityscreen .card {
  border: none;
  background: #202020;
}
#capacityscreen .card-body {
  background: #202020;
  padding: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px #202020;
  border-radius: 2px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 20px #202020;
}

.current-selected {
  display: none;
}

.page-title h3 {
  font-family: "ShellHeavy";
  font-size: 22px;
}

.capacity .k-header {
  background: transparent;
  text-align: center;
  min-width: 117px;
}

.capacity .k-header button {
  border: 1px solid #404040;
  border-radius: 4px;
  width: 100%;
}

.unit-type .k-grid,
.unit-type .k-grid-header {
  background: transparent;
  border: 0;
}

.unit-type .k-grid .k-header button {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
  width: 100%;
}

#capacityscreen .k-grid td .td-data button {
  border: 0;
}

.unit-type .k-grid th {
  padding: 0 4px 4px 0;
}

.unit-type .k-grid td {
  border: 0;
  padding-left: 2px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.unit-type .k-grid-header .k-header > .k-link {
  margin: 0;
  padding: 5px 0;
}

.unit-type .k-grid td .td-data {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid #404040;
  border-radius: 4px;
  text-align: center;
}

.unit-type .k-grid .k-alt {
  background: transparent;
}

.unit-type .k-grid td .td-data {
  background-color: #303030;
}

.unit-type .k-grid .k-alt td .td-data {
  background-color: #383838;
}

.unit-type .k-grid tr:first-child .k-header:first-child a {
  display: none;
}

/* .unit-type .k-grid tr:first-child th:nth-child(2){

} */

.unit-type .k-grid-header-wrap {
  border: 0;
}

.unit-type .k-grid tbody tr.k-state-hover,
.unit-type .k-grid tbody tr:hover {
  background-color: rgba(217, 217, 217, 0.065);
}

.unit-type .k-column-resizer {
  right: 20px !important;
  top: 6px !important;
  /* display: none !important; */
}

.unit-type .k-grid th:hover .k-column-resizer {
  display: block !important;
}

body > iframe {
  display: none;
}

.bg-solid-toggle,
.bg-solid-toggle:active {
  background: #202020;
}

.button-pointer:hover {
  cursor: pointer;
}

.incr-provider-data {
  overflow: auto hidden;
}

.incr-provider-data table {
  width: auto;
}

.k-grid-header-wrap .title {
  text-align: left;
}

.capacity-screen-no-data h3 {
  left: 37.8%;
  color: rgb(89, 89, 89);
  border: 1px solid #595959;
  padding: 10px 10px;
  border-radius: 4px;
  position: absolute;
  top: 47%;
  color: #7f7f7f !important;
  font-family: "ShellLight";
}

/* #filterPanel.sw {
  display: block;
}

#filterPanel {
  display: none;
} */

#capacityscreen {
  /* overflow-x: scroll; */
}

#capacityscreen .k-grid td {
  border-width: 0 0 0 0px;
}

#capacityscreen .k-grid th .td-data {
  /* width: 100%; */
  padding: 6px;
  border: 1px solid #404040;
  border-radius: 4px;
  text-align: center;
}

#capacityscreen td {
  white-space: nowrap !important;
}

#capacityscreen .k-grid th .td-data button {
  border: none !important;
}

.multiselect-picker {
  /* width: 130px; */
}

.multiselect-picker > span {
  display: none;
}

.control-dd {
  border: 0 !important;
  background: transparent !important;
  color: #7f7f7f !important;
  padding: 0 !important;
  min-height: auto !important;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 180px !important;
  cursor: pointer !important;
}

.select-option-menu > div {
  left: -15px;
  background: #202020;
  color: #f7f7f7;
  border: 1px solid #595959 !important;
}
#provider .select-option-menu > div {
  width: 207px;
}
#ptype .select-option-menu > div {
  width: 195px;
}

.control-dd > div {
  padding: 0.5px !important;
  line-height: 11px !important;
  box-shadow: none !important;
  /* height: 22px !important; */
  margin-left: 67px;
  width: 100%;
  cursor: pointer;
}
#ptype .control-dd > div {
  margin-left: 55px;
}

.control-dd > div > div {
  color: #f7f7f7;
  /* margin-left: 10px; */
}

.select-option-menu > div > div {
  padding: 0;
}

/* .select-option-menu > div > div input {
  display: none;
} */

/* .select-option-menu > div > div > div>div:hover {
  background: #003c88 !important;
}*/

.count {
  color: #f7f7f7;
  margin-left: 10px;
}

.select-option-menu {
  z-index: 9999 !important;
}

.select-option-menu > div > div > div {
  background: transparent !important;
}

.td-data {
  min-width: 80px;
  /* max-width: 200px; */
  margin: 0 1px 0 1px;
  height: 35px;
}

/* div#all-options{
  width:100%;
} */

div#all-options > div {
  /* background: transparent; */
  /* margin: 0.3px; */
  background: rgb(0, 60, 136);
}

div#all-options > div > div {
  font-size: 14px;
  color: #f7f7f7;
}

.incremental-capacity input {
  width: 68%;
}
.incr-baseline .td-data {
  min-width: auto;
  max-width: auto;
  width: 100%;
}

.no-pointer {
  cursor: context-menu !important;
}

.text-centre {
  text-align: center !important;
}

.item-line-height label {
  line-height: 1.6em;
}

.data-grid-incr-capacity {
  overflow: auto;
  /* height: 300px; */
}

.btnlink-height {
  height: 35px;
  padding: 5px;
}
.tdbgc {
  background-color: #303030;
}
.since-last-update {
  background-color: #303030;
}
.since-last-update:after {
  content: '';
  border-style: solid;
  border-radius: 4px 0 0 0;
  border-width: 6px 6px 6px 6px;
  border-color: #ffc806 transparent transparent #ffc806;
  left: 0;
  top: 1px;
  position: absolute;
}
.k-popup {
  background-color: rgb(24, 24, 24);
}
.versiontd {
  color: #a6a6a6;
  font-size: 13px;
  line-height: 19px;
  font-family: "ShellLight";
}

.k-window .k-overlay {
  position: fixed;
  opacity: 0.5 !important;
}

#capacityscreen .k-grid td .td-data.unit-sub-type {
  margin: 0 5px 0 0 !important;
}
.assetname {
  text-align: right;
  padding: 7px 10px 7px 7px;
  border: 1px solid #404040;
  border-radius: 4px;
  color: #fff;
}
.select-text {
  position: absolute;
}
/* .k-popup {
  margin-top: 10px;
  margin-left: -15px;
} */
