.dashboard-widgets .col-md-6,
.dashboard-widgets .col-md-4 {
  margin-bottom: 16px;
}

.box-radius {
  border-radius: 3px;
}

.k-chart,
.k-stockchart {
  height: 344px;
}
