body {
  scrollbar-width: none;
}

.general-configuration {
  padding: 0 1rem;
}

.data-area-window-menu {
  padding: 0;
}

.data-area-window-menu a {
  color: #d9d9d9;
}

.data-area-window-menu.navbar-expand .navbar-nav .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
}

.data-area-window-menu a.active {
  border-bottom: 3px solid #fbce07;
}

.tableRowsInfo {
  padding: 0.5rem;
}

.table {
  font-size: 14px;
}

.common-Datatable-add-delete nav {
  padding: 16px 16px 0;
}

.common-Datatable-add-delete a {
  padding-left: 0;
  padding-right: 16px;
}

.table thead th,
.table td,
.table th {
  border: 0px;
}

.data-area-subMenuContainer {
  padding: 8px 16px;
}

thead tr th:first-child,
tbody tr td:first-child {
  /* width: 20px; */
  padding-right: 0;
}

.react-bootstrap-table th.sortable {
  padding: 5px 10px;
}

.react-bootstrap-table
  nav.bg-none.justify-content-between.navbar.navbar-expand.navbar-light {
  padding: 0;
}

.react-bootstrap-table thead tr th:first-child {
  padding-top: 0;
}

.checkBoxForMetaData {
  width: 200px;
  float: right;
  padding: 10px;
}

.header-title {
  font-family: "ShellBold";
  font-size: 16px;
  line-height: 24px;
}

.header-title span {
  font-family: "ShellMedium";
}

.navbar-nav a {
  font-family: "ShellMedium";
  font-size: 14px;
  padding: 0.5rem 0;
}
.breadcrumb-item .active,
.breadcrumb-item a {
  font-family: "ShellMedium";
  font-size: 14px;
}

.breadcrumb-item a {
  text-decoration: underline;
}

.k-master-row td:first-child button:first-child {
 /* margin-right: 1rem;*/
}

#percentage {
  position: fixed;
  top: 150px;
  width: 60px;
  left: 10px;
  height: 20px;
  background-color: lightgrey;
  text-align: center;
}

#track {
  position: fixed;
  left: 80px;
  border-radius: 10px;
  width: 15px;
  height: 300px;
  background-color: #000000;
}

#slider {
  position: absolute;

  border-radius: 10px;
  width: 100%;
  background: linear-gradient(to right, #1e5799 0%, #89b3d3 51%, #1e5799 100%);
}

/* .k-grid-content::-webkit-scrollbar,
  .general-configuration .simplebar-content-wrapper::-webkit-scrollbar {
    width: 0 !important;
  }
  .k-grid-content,
  .general-configuration .simplebar-content-wrapper {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  } */

/* .k-grid-header {
    padding: 0 !important;
  } */

.simplebar-content-wrapper {
  overflow: hidden scroll;
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: #ffffff !important;
}

/*Toast Notification*/
.Toastify__toast-container ul li {
  list-style: disc;
  margin-left: 20px;
  text-align: left;
}

.k-master-row.k-state-selected,
.k-grid tbody tr.k-master-row.k-state-selected:hover {
  background: #000000;
}
