.navcontainer {
  width: 350px;
   height: 250px; 
  background: #282828;
  border: 1px solid #595959;
  /* margin-left: 15px; */
  border-radius: 4px;
  overflow-x: hidden;
  padding: 2px;
  overflow-y: auto;
}

.togleBtn {
  background: #282828;
  border-right: 1px solid #595959;
  border-top: 1px solid #595959;
  border-bottom: 1px solid #595959;
  border-radius: 4px;
  padding: 10px 7px 10px 5px;
  cursor: pointer;
}
.togleBtn:hover {
  background: #595959;
}
.hidden {
  display: none;
}

.show {
  display: block;
}
#listItems ul li {
  line-height: 1.4em;
  display: block;
  cursor: pointer;
  background-color: #7f7f7f26;
}

#listItems ul li:hover,
#listItems ul li:focus {
  background-color: rgb(0, 60, 136, 0.2);
}
#listItems .active {
  /*background-color: #003c88;*/
}
