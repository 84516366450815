.topbar-menu {
  z-index: 1;
  padding-left: 1rem;
}

.topbar-menu .fa {
  font-size: 20px;
  color: #7f7f7f;
}

.topbar-menu-breadcrumbs {
  padding: 0.7rem 1rem;
}

.topbar-menu ol,
.topbar-menu .nav-link {
  padding: 0;
  margin: 0;
}

.topbar-menu button {
  padding: 0.75rem;
  border: 0;
  border-radius: 0;
}

.topbar-menu-user-tray ul,
.topbar-menu-user-tray li {
  margin: 0;
  padding: 0;
}

.topbar-menu-user-tray .navbar {
  padding: 0;
}

.topbar-menu .btn:focus,
.topbar-menu .btn:active {
  outline: none !important;
  box-shadow: none;
  transition: none !important;
}

.topbar-menu input.form-control {
  border: 0;
  border-radius: 2px;
  padding: 0 10px;
  height: auto;
}

.search-form {
  padding: 0.7rem 0;
}

.topbar-menu .search-form,
.topbar-menu .form-group {
  margin: 0;
}

.dropdown-menu {
  top: 95%;
  border-radius: 0;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  width: 250px;
  border-top: 0px;
  z-index: 1;
}

/* .search-form input[type="text"]{
    width:300px;
} */
