li {
  list-style: none;
}

.sidebar-menu-item .fa {
  font-size: 24px;
  color: #7f7f7f;
}

.sidebar-menu {
  width: 4rem !important;
  z-index: 9;
}

button {
  border: 0;
}

button:focus {
  outline: 0;
}

.sidebar-menu-item button,
.sidebar-menu-item button:hover,
.sidebar-menu-item button:focus,
.sidebar-menu-item button:active {
  outline: none !important;
  box-shadow: none !important;
  transition: none !important;
  border: 0px !important;
  background: none !important;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li,
.app-shell-logo {
  text-align: center;
}

button {
  background: none;
  outline: 0;
}

.sidebar-menu-item button::before,
.sidebar-menu-item button::after {
  display: none !important;
}

.menu-space {
  padding-left: 16px;
}

.sidebar-menu-item .dropdown-menu b {
  font-size: 14px;
}

.menu-item {
  padding: 0.84rem 0;
}

.content {
  position: relative;
  left: 0;
  z-index: 3;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  max-width: 100%;
  transition: left 0.2s ease-in;
}